import React, { useEffect } from 'react';
import './target.css';

type TargetSvgProps = {
  className?: string;
  onClick?: () => void;
  state?: 'ALIVE' | 'HIDDEN' | 'DEAD';
  id: string;
};

export const Target = (props: TargetSvgProps) => {
  const [targetStateCss, setTargetStateCss] = React.useState('targetSvgBodyAlive');
  const [targetFaceStateCss, setTargetFaceStateCss] = React.useState('targetSvgFaceAlive');

  useEffect(() => {
    switch (props.state) {
      case 'ALIVE':
        setTargetStateCss('targetSvgBodyAlive');
        setTargetFaceStateCss('targetSvgFaceAlive');
        break;
      case 'HIDDEN':
        setTargetStateCss('targetSvgBodyHidden');
        setTargetFaceStateCss('targetSvgFaceHidden');
        break;
      case 'DEAD':
        setTargetStateCss('targetSvgBodyDead');
        setTargetFaceStateCss('targetSvgFaceDead');
        break;
      default:
        setTargetStateCss('targetSvgBodyHidden');
        setTargetFaceStateCss('targetSvgFaceHidden');
        break;
    }
  }, [props.state]);

  return (
    <div onClick={props.onClick} className="targetSvgBox">
      <div className="target-text-header">{props.id}</div>
      <div className={`targetAnimation ${props.state === 'HIDDEN' ? `target-animate-out` : 'target-animate-in'}`}>
        <svg version="1.1" viewBox="0 0 99.098 93.039" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-54.16 -47.804)">
            <g transform="translate(-44.349 -13.584)" className={`${targetStateCss}`}>
              <path
                className="targetSvg"
                d="m98.642 154.3 0.01992-38.24 21.358-20.193-0.22299-23.637 11.645-10.704h14.321l10.852 5.5253 9.4153 21.903 31.393 13.85 0.0508 51.495z"
              />
            </g>
            <path
              className={targetFaceStateCss}
              d="m113.47 86.028c0 14.156-5.7938 23.613-12.973 22.486-7.3351-1.1514-15.352-8.3306-15.352-22.486s-1.5861-0.58966 13.136-2.2496c13.617-1.5355 15.189-11.906 15.189 2.2496z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
