import React, { useEffect } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import BluetoothTerminal from 'bluetooth-terminal';

import targetsConfig from './config/zielona.config.json';
import { ShootingRangeConfiguration, TargetInstance, TargetsBorder } from './config/shooting-range-configuration.types';
import './App.css';
import { Border } from './components/border';

const terminal = new BluetoothTerminal();

const targetsConfiguration: ShootingRangeConfiguration = targetsConfig as ShootingRangeConfiguration;

export const App = () => {
  const [borders, setBorders] = React.useState<TargetsBorder[]>(targetsConfiguration.borders);
  const [btData, setBtData] = React.useState<string[]>([]);
  useEffect(() => {
    setBorders(targetsConfiguration.borders);
  }, []);

  terminal.receive = function (data: string) {
    console.log(data);
    if (data.includes('UC24')) {
      setBorders((br) => {
        const brs = [...br].map((_br) => {
          const target = _br.targets.find((tr) => tr.name === 'C24');
          if (target) {
            const resultTargets = _br.targets.map((tr) => {
              if (tr.name === 'C24') {
                console.log('zmieniam go!');
                const newTr = { ...tr };
                newTr.active = false;
                return newTr;
              }
              return tr;
            });
            const newBr = { ..._br };
            newBr.targets = resultTargets;
            return newBr;
          }
          return _br;
        });
        return brs;
      });
      console.log('C24 up');
    }
    if (data.includes('DC24')) {
      console.log('D24 down');
    }
    setBtData((btdata) => {
      const dt = [...btdata, data];
      return dt;
    });
  };

  const setTargets = (borderIndex: number, targets: TargetInstance[]) => {
    const bordersArr = [...borders].map((border) => {
      if (borderIndex === border.index) {
        const newBorder = { ...border };
        newBorder.targets = targets;
        return newBorder;
      }
      return border;
    });
    setBorders(bordersArr);
  };

  const openBt = () => {
    terminal.connect();
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        // direction="column"
        spacing={{ xs: 5, sm: 5, md: 5 }}
        // alignItems="stretch"
        sx={{ display: 'flex' }}
      >
        {borders.map((border) => (
          <Grid item style={{ height: '100%', width: '100%' }} key={`${border.name}${border.index}`}>
            <Border
              borderName={border.name}
              shootingPositions={targetsConfiguration.shootingPositions}
              targets={border.targets}
              setTargets={(targets) => setTargets(border.index, targets)}
            />
          </Grid>
        ))}
      </Grid>
      <Button onClick={() => openBt()}>Connect</Button>
      {btData.map((data, index) => (
        <Typography key={`btdata${index}`}>{data}</Typography>
      ))}
    </Container>
  );
};
