import React from 'react';
import Box from '@mui/material/Box';
import { Grid, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import Button from '@mui/material/Button';

import './border.css';

import { TargetInstance } from '../config/shooting-range-configuration.types';

import { Target } from './target';

type BorderProps = {
  className?: string;
  borderName: string;
  shootingPositions: number;
  targets: TargetInstance[];
  setTargets: (targets: TargetInstance[]) => void;
};

const menuSettings = ['Podnieś wszystkie', 'Połóż wszystkie'];

export const Border = (props: BorderProps) => {
  const [_anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [targetIsActive, setTargetIsActive] = React.useState<boolean[]>(new Array(props.shootingPositions).fill(false));
  const [targetIsHit, _setTargetIsHit] = React.useState<boolean[]>(new Array(props.shootingPositions).fill(false));
  const [_targetIsBroken, _setTargetIsBroken] = React.useState<boolean[]>(
    new Array(props.shootingPositions).fill(false),
  );
  const [shootingPositions, _setShootingPositions] = React.useState<boolean[]>(
    new Array(props.shootingPositions).fill(false),
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickTarget = (index: number) => {
    const targetIsActiveArr = [...targetIsActive];
    targetIsActiveArr[index] = !targetIsActive[index];
    setTargetIsActive(targetIsActiveArr);

    const newArr = [...props.targets];
    newArr[index].active = !props.targets[index].active;

    props.setTargets(newArr);
  };

  // useEffect(() => {
  //   console.log('target changed!');
  // }, props.targets);

  return (
    <Box className="BorderMainBox">
      <Box sx={{ width: '100%' }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <FlagIcon sx={{ display: { xs: 'flex' }, mr: 1, color: '#ff5722' }} />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {props.borderName}
          </Typography>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <SettingsInputComponentIcon sx={{ color: '#00897b' }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {menuSettings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    sx={{ color: '#1B5E20', margin: '0', padding: '0', fontWeight: '500' }}
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {menuSettings.map((page) => (
              <Button
                variant="text"
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', marginLeft: '10px' }}
                color="success"
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Box>
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 6, md: 12 }}>
        {shootingPositions.map((_position, index) => {
          // console.log('dziala?');
          let targetIndex = -1;
          // console.log(props.targets);
          props.targets.forEach((target, tIndex) => {
            if (target.position === index) {
              targetIndex = tIndex;
              // console.log('chuja?');
            }
          });
          if (targetIndex > -1) {
            return (
              <Grid item xs={12 / props.shootingPositions} key={props.targets[targetIndex].name}>
                <Target
                  key={targetIndex}
                  id={`#${targetIndex + 1}`}
                  state={!targetIsActive[targetIndex] ? 'HIDDEN' : targetIsHit[targetIndex] ? 'DEAD' : 'ALIVE'}
                  onClick={() => onClickTarget(targetIndex)}
                />
              </Grid>
            );
          }
          return <Grid item xs={12 / props.shootingPositions} key={index}></Grid>;
        })}
      </Grid>
    </Box>
  );
};
